import { createContext } from 'react';

export const RoutePropsContexts = createContext({
  userId: 0,
  privilages: {},
  ajaxUtil: () => {},
  setHeader: () => {},
  saveCurrentState: () => {},
  setNotification: () => {},
  setModalPopup: () => {},
  loadingFunction: () => {},
  messagesUtil: {},
  designationId: 0,
  globalConstants: {},
  loggedInUser: {},
  exportResponseHandler: () => {}
});

export const MoneyContext = createContext(undefined);

export const ResponsiveContainerContext = createContext({
  height: "",
  width: ""
});
